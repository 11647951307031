<template>
  <div :class="{ 'dark-mode': isDarkMode }">
    <!-- <v-row>
      <v-col>
        <v-img height="50" width="160" src="@/assets/logo-dark@2x.png"></v-img>
      </v-col>
      <v-col cols="1">Home</v-col>
      <v-col cols="1">About</v-col>
      <v-col cols="1">FAQ</v-col>
      <v-col cols="1"><v-btn>Verify Letter</v-btn></v-col>
      <v-col cols="1"><v-btn>Sign In</v-btn></v-col>
      <v-col cols="1"><v-btn>Sign Up</v-btn></v-col>
    </v-row> -->

    <!-- <v-card flat tile>
      <v-app-bar dense flat elevation="0" color="white" height="60px" app> -->
    <v-card color="grey lighten-4" flat tile>
      <v-app-bar dense flat color="white" height="60px" fixed>
        <!-- <v-app-bar-nav-icon
          class="hidden-md-and-up"
          @click="drawer = !drawer"
          large
          color="green"
        ></v-app-bar-nav-icon> -->
        <v-card class="hidden-md-and-up" elevation="0">
          <v-app-bar-nav-icon @click="drawer = !drawer" large color="green">
          </v-app-bar-nav-icon>
        </v-card>
        <v-card class="elevation-0">
          <v-img
            height="50"
            width="160"
            src="@/assets/logo-dark@2x.png"
          ></v-img>
        </v-card>
        <v-spacer></v-spacer>
        <!-- <v-card class="hidden-md-and-up" elevation="0">

          <v-app-bar-nav-icon @click="drawer = !drawer"   large color="green">
          </v-app-bar-nav-icon>
        </v-card> -->
        <v-toolbar-title
          class="mr-10 hidden-md-and-down"
          style="font-weight: 500; font-size: 17px"
          @click="check('HOME')"
          >Home</v-toolbar-title
        >
        <v-toolbar-title
          class="mr-10 hidden-md-and-down"
          style="font-weight: 500; font-size: 17px; cursor: pointer"
          @click="check('ABOUT')"
          >About</v-toolbar-title
        >
        <v-toolbar-title
          class="mr-10 hidden-md-and-down"
          style="font-weight: 500; font-size: 17px; cursor: pointer"
          @click="check('FAQs')"
          >FAQ</v-toolbar-title
        >
        <v-btn
          id="verifyButton"
          color="black"
          class="white--text mr-3 buttonhover hidden-md-and-down"
          @click="$router.push('/VerifyCredentials')"
          >Verify Letter</v-btn
        >
        <v-btn
          class="Log white--text mr-3 hidden-md-and-down"
          color="green"
          @click="$router.push('/Login')"
          >Sign In</v-btn
        >
        <v-btn
          class="Log white--text mr-3 hidden-md-and-down"
          color="green"
          @click="$router.push('/RegisterWithUs')"
          >Sign Up</v-btn
        >
      </v-app-bar>
    </v-card>

    <v-navigation-drawer
      v-model="drawer"
      left
      app
      clipped
      width="200px"
      class="navigationDrawer"
      dark
    >
      <v-list dark dense rounded>
        <v-card
          height="50px"
          elevation="0"
          dense
          style="background-color: rgb(52, 51, 51)"
        >
          <div class="iconcss">
            <v-icon class="ma-2" @click="drawer = !drawer">mdi-close</v-icon>
          </div>
        </v-card>
        <v-list-item-group
          link
          dense
          rounded
          v-model="selectedItem"
          color="green"
          active-class="green"
          no-action
        >
          <v-list-item
            color="green"
            v-for="item in menuItems"
            :key="item.title"
            @click="check(item.link)"
          >
            <v-list-item-icon class="pr-0 mr-0">
              <v-icon small color="white" v-text="item.icon"></v-icon>
            </v-list-item-icon>
            <v-list-item-content>
              <v-list-item-title
                class="white--text fontStyle"
                v-text="item.title"
              ></v-list-item-title>
            </v-list-item-content>
          </v-list-item>
        </v-list-item-group>
      </v-list>
    </v-navigation-drawer>
    <section id="Home">
      <v-row>
        <v-col cols="12" lg="6" md="6" sm="12" xs="12" class="fakeletter">
          <v-img
            src="@/assets/devices@2x.png"
            v-show="
              $vuetify.breakpoint.name == 'md' ||
              $vuetify.breakpoint.name == 'lg'
            "
          ></v-img>

          <v-img
            contain
            src="@/assets/devices@2x.png"
            v-show="
              $vuetify.breakpoint.name == 'xs' ||
              $vuetify.breakpoint.name == 'sm'
            "
          ></v-img>
          <!-- <v-img src="@/assets/My_Logo.svg" contain  ></v-img> -->
        </v-col>
        <v-col cols="12" lg="4" md="4" sm="12" xs="12" class="fakeletter">
          <h2
            class="heading1 mt-12"
            v-show="
              $vuetify.breakpoint.name == 'md' ||
              $vuetify.breakpoint.name == 'lg'
            "
          >
            15% of Relieving Letters are being Faked!
          </h2>

          <h1
            class="abc"
            v-show="
              $vuetify.breakpoint.name == 'xs' ||
              $vuetify.breakpoint.name == 'sm'
            "
          >
            15% of Relieving Letters are being Faked!
          </h1>
          <!-- <v-col cols="12" lg="4" md="4" sm="12" xs="12" class="fakeletter hidden-md-and-down">
          <h2 class="heading1 mt-12">
            15% of Relieving Letters are being Faked!
          </h2> -->

          <h2 class="heading2 abc">
            RelievingLetter.com ensures the Relieving Letters you issue are
            fake-proof
          </h2>
          <div class="abc">
            <v-btn
              id="verifyButton"
              color="black"
              class="white--text mt-6 abc buttonhover"
              @click="$router.push('/RegisterWithUs')"
              >Get Started Today !</v-btn
            >
          </div>
        </v-col>
      </v-row>
    </section>
    <section id="About" class="pt-16">
      <v-row class="d-flex justify-center">
        <v-col cols="12" md="6" lg="6" sm="10" xs="10">
          <h3 align="center" style="color: grey">RelievingLetter.com</h3>
          <h2 class="heading3 mr-2 ml-2" align="center">
            Powered by blockchain and utilizing its key
          </h2>
          <h2 class="heading3" align="center">
            characteristics of immutability,
          </h2>
          <h2 class="heading3" align="center">
            decentralization and a distributed ledger.
          </h2>
        </v-col>
      </v-row>
      <div>
        <v-row class="mx-8 d-flex justify-center mt-12">
          <v-col cols="12" md="5" sm="12" xs="12" lg="5">
            <h3 style="color: rgb(80, 79, 79)">
              Participate in an industry wide HR initiative
            </h3>
            <h5
              style="
                font-weight: 300;
                color: rgb(132, 132, 132);
                font-size: 17px;
              "
            >
              Sign-up and participate in an industry wide HR initiative, which
              100's of organizations like yours are already on-board
            </h5>
          </v-col>
          <v-col cols="12" md="5" sm="12" xs="12" lg="5">
            <h3 style="color: rgb(80, 79, 79)">
              Avoid having to respond to background verification requests
            </h3>
            <h5
              style="
                font-weight: 300;
                color: rgb(132, 132, 132);
                font-size: 17px;
              "
            >
              For your ex-employees, you don't need to respond to any further
              background verification requests and thereby save on time and
              hassles
            </h5>
          </v-col>
        </v-row>
      </div>
      <v-row>
        <v-col cols="12">
          <h2
            align="center"
            class="mb-10 pt-12 mt-12"
            style="color: rgb(82, 82, 82); font-size: 35px; margin-top: 50px"
          >
            Our Customers
          </h2>
          <v-row class="d-flex justify-center">
            <v-col cols="2">
              <v-img src="@/assets/nstarx.png"></v-img>
            </v-col>
            <v-col cols="2">
              <v-img src="@/assets/maarga.png"></v-img>
            </v-col>
            <v-col cols="2">
              <v-img src="@/assets/uMobi.png"></v-img>
            </v-col>
            <v-col cols="2">
              <v-img src="@/assets/mobil80.png"></v-img>
            </v-col>
            <v-col cols="2">
              <v-img src="@/assets/knowledgefoundry.png"></v-img>
            </v-col>
          </v-row>
          <v-row class="d-flex justify-center my-10">
            <v-col cols="2">
              <v-img src="@/assets/ed.png"></v-img>
            </v-col>
            <v-col cols="2">
              <v-img src="@/assets/alchemy.png"></v-img>
            </v-col>
            <v-col cols="2">
              <v-img src="@/assets/edujournal.png"></v-img>
            </v-col>
            <v-col cols="2">
              <v-img src="@/assets/hourglass.png"></v-img>
            </v-col>
            <v-col cols="2">
              <v-img src="@/assets/eai.png"></v-img>
            </v-col>
          </v-row>
          <v-row class="d-flex justify-center my-10">
            <v-col cols="2">
              <v-img src="@/assets/starscapes.png"></v-img>
            </v-col>
            <v-col cols="2">
              <v-img src="@/assets/wrktop.png"></v-img>
            </v-col>
            <v-col cols="2">
              <v-img src="@/assets/verve.png"></v-img>
            </v-col>
            <v-col cols="2">
              <v-img src="@/assets/vedicpac.png"></v-img>
            </v-col>
            <v-col cols="2">
              <v-img src="@/assets/brightsword.png"></v-img>
            </v-col>
          </v-row>
          <v-row class="d-flex justify-center">
            <v-col cols="2">
              <v-img src="@/assets/prznce.png"></v-img>
            </v-col>
            <v-col cols="2">
              <v-img src="@/assets/cantorspace.png"></v-img>
            </v-col>
          </v-row>
        </v-col>
      </v-row>
    </section>
    <section id="FAQs" class="pt-16">
      <div>
        <v-row class="mx-12">
          <v-col cols="12">
            <h2
              class="mt-12"
              align="center"
              style="
                color: rgb(82, 82, 82);
                font-size: 35px;
                font-family: Inter, ui-sans-serif, system-ui, sans-serif,
                  Apple Color Emoji, Segoe UI Emoji, Segoe UI Symbol,
                  Noto Color Emoji;
              "
            >
              Frequently Asked Questions
            </h2>
          </v-col>
          <v-col class="mt-6" cols="12">
            <v-row>
              <v-col cols="12" md="6" lg="6" sm="12" xs="12">
                <v-expansion-panels>
                  <v-expansion-panel class="rounded-lg">
                    <v-expansion-panel-header class="FAQ">
                      1.What is RelievingLetter?
                    </v-expansion-panel-header>
                    <v-expansion-panel-content
                      class="font-weight-light grey--text"
                    >
                      RelievingLetter is a SaaS application for securing and
                      verifying digital documents, rendering them tamper-proof.
                      It uses encryption and blockchain technology that makes it
                      free from the possibility of tampering, loss of critical
                      data and reliance on unknown third parties. It is intended
                      for use of organizations which have one or more users, and
                      which want employee exit-related documents to be securely
                      and independently verified by anyone (inside or outside
                      the organization).
                    </v-expansion-panel-content>
                  </v-expansion-panel>
                </v-expansion-panels>
              </v-col>
              <v-col cols="12" md="6" lg="6" sm="12" xs="12">
                <v-expansion-panels>
                  <v-expansion-panel class="rounded-lg">
                    <v-expansion-panel-header class="FAQ">
                      2.On what platform is RelievingLetter available?
                    </v-expansion-panel-header>
                    <v-expansion-panel-content
                      class="font-weight-light grey--text"
                    >
                      RelievingLetter consists of a web portal
                      (relievingletter.com), compatible with all major web
                      browsers
                    </v-expansion-panel-content>
                  </v-expansion-panel>
                </v-expansion-panels>
              </v-col>
            </v-row>
          </v-col>
          <v-col cols="12">
            <v-row>
              <v-col cols="12" md="6" lg="6" sm="12" xs="12">
                <v-expansion-panels>
                  <v-expansion-panel class="rounded-lg">
                    <v-expansion-panel-header class="FAQ">
                      3.Who can use RelievingLetter?
                    </v-expansion-panel-header>
                    <v-expansion-panel-content
                      class="font-weight-light grey--text"
                    >
                      Any organisation can apply online to create an account
                      through the RelievingLetter Portal. Sign up is instant and
                      comes with a free 30-day trial allowance
                    </v-expansion-panel-content>
                  </v-expansion-panel>
                </v-expansion-panels>
              </v-col>
              <v-col cols="12" md="6" lg="6" sm="12" xs="12">
                <v-expansion-panels>
                  <v-expansion-panel class="rounded-lg">
                    <v-expansion-panel-header class="FAQ">
                      4.Can multiple use the same account?
                    </v-expansion-panel-header>
                    <v-expansion-panel-content
                      class="font-weight-light grey--text"
                    >
                      Once an organization has set up an account, with
                      RelievingLetter, the owner (initiator) can add other
                      members, as authorized signatories. There is no upper
                      limit to the number of users that can be added.
                    </v-expansion-panel-content>
                  </v-expansion-panel>
                </v-expansion-panels>
              </v-col>
            </v-row>
          </v-col>
          <v-col cols="12">
            <v-row>
              <v-col cols="12" md="6" lg="6" sm="12" xs="12">
                <v-expansion-panels>
                  <v-expansion-panel class="rounded-lg">
                    <v-expansion-panel-header class="FAQ">
                      5.How do i add credibility to my organization?
                    </v-expansion-panel-header>
                    <v-expansion-panel-content
                      class="font-weight-light grey--text"
                    >
                      The organization can apply for a “verified” status. They
                      will be contacted to complete the registration process. A
                      manual KYC process will be done, that is usually completed
                      in less than 2 working days
                    </v-expansion-panel-content>
                  </v-expansion-panel>
                </v-expansion-panels>
              </v-col>
              <v-col cols="12" md="6" lg="6" sm="12" xs="12">
                <v-expansion-panels>
                  <v-expansion-panel class="rounded-lg">
                    <v-expansion-panel-header class="FAQ">
                      6.Can an individul user have multiple accounts?
                    </v-expansion-panel-header>
                    <v-expansion-panel-content
                      class="font-weight-light grey--text"
                    >
                      Yes, each account is uniquely identified by their email
                      address and phone number
                    </v-expansion-panel-content>
                  </v-expansion-panel>
                </v-expansion-panels>
              </v-col>
            </v-row>
          </v-col>
          <v-col cols="12">
            <v-row>
              <v-col cols="12" md="6" lg="6" sm="12" xs="12">
                <v-expansion-panels>
                  <v-expansion-panel class="rounded-lg">
                    <v-expansion-panel-header class="FAQ">
                      7.How do I secure a relieving letter?
                    </v-expansion-panel-header>
                    <v-expansion-panel-content
                      class="font-weight-light grey--text"
                    >
                      Simply log in with your user credentials, upload the
                      document to be secured and submit it. It is immediately
                      converted into a secure form and an authenticity record
                      (main details including timestamp) is written to the
                      blockchain.
                    </v-expansion-panel-content>
                  </v-expansion-panel>
                </v-expansion-panels>
              </v-col>
              <v-col cols="12" md="6" lg="6" sm="12" xs="12">
                <v-expansion-panels>
                  <v-expansion-panel class="rounded-lg">
                    <v-expansion-panel-header class="FAQ">
                      8.Why is there a delay in completion of process?
                    </v-expansion-panel-header>
                    <v-expansion-panel-content
                      class="font-weight-light grey--text"
                    >
                      Entries written to the blockchain usually take a few
                      minutes to process, as this is the way blockchain
                      architecture works. Don't worry! You can continue working
                      on other documents, and the system will automatically
                      update the status of the document, once the blockchain
                      write is completed
                    </v-expansion-panel-content>
                  </v-expansion-panel>
                </v-expansion-panels>
              </v-col>
            </v-row>
          </v-col>
          <v-col cols="12">
            <v-row>
              <v-col cols="12" md="6" lg="6" sm="12" xs="12">
                <v-expansion-panels>
                  <v-expansion-panel class="rounded-lg">
                    <v-expansion-panel-header class="FAQ">
                      9.How will RelievingLetter help me while hiring?
                    </v-expansion-panel-header>
                    <v-expansion-panel-content
                      class="font-weight-light grey--text"
                    >
                      Costly background verification can be eliminated
                      completely, as the authenticity of the document can be
                      established with a few clicks. This can save thousands of
                      rupees in costs, and weeks of delay
                    </v-expansion-panel-content>
                  </v-expansion-panel>
                </v-expansion-panels>
              </v-col>
              <v-col cols="12" md="6" lg="6" sm="12" xs="12">
                <v-expansion-panels>
                  <v-expansion-panel class="rounded-lg">
                    <v-expansion-panel-header class="FAQ">
                      10.Which upload document formats are supported
                    </v-expansion-panel-header>
                    <v-expansion-panel-content
                      class="font-weight-light grey--text"
                    >
                      At present, RelievingLetter supports input in .pdf, .doc
                      and .docx. More formats will be available in future
                      releases
                    </v-expansion-panel-content>
                  </v-expansion-panel>
                </v-expansion-panels>
              </v-col>
            </v-row>
          </v-col>
          <v-col cols="12">
            <v-row>
              <v-col cols="12" md="6" lg="6" sm="12" xs="12">
                <v-expansion-panels>
                  <v-expansion-panel class="rounded-lg">
                    <v-expansion-panel-header class="FAQ">
                      11.Will the signed document have a different format?
                    </v-expansion-panel-header>
                    <v-expansion-panel-content
                      class="font-weight-light grey--text"
                    >
                      The signed RelievingLetter document will always be a PDF
                      document. The original document is converted to PDF format
                      upon upload. An additional page will be appended to the
                      original document, which will contain the important
                      security details. The final document will simply be the
                      source document and the appended page together in a single
                      PDF file, which is available for viewing and sharing
                    </v-expansion-panel-content>
                  </v-expansion-panel>
                </v-expansion-panels>
              </v-col>
              <v-col cols="12" md="6" lg="6" sm="12" xs="12">
                <v-expansion-panels>
                  <v-expansion-panel class="rounded-lg">
                    <v-expansion-panel-header class="FAQ">
                      12.Will the signed document have a different format?
                    </v-expansion-panel-header>
                    <v-expansion-panel-content
                      class="font-weight-light grey--text"
                    >
                      There is no need for a hard copy at any stage in the
                      process. The input document is expected to be in digital
                      form, in one of the accepted formats (see above). After
                      securing it with the app, RelievingLetter will generate a
                      soft copy signed document.
                    </v-expansion-panel-content>
                  </v-expansion-panel>
                </v-expansion-panels>
              </v-col>
            </v-row>
          </v-col>
          <v-col cols="12">
            <v-row>
              <v-col cols="12" md="6" lg="6" sm="12" xs="12">
                <v-expansion-panels>
                  <v-expansion-panel class="rounded-lg">
                    <v-expansion-panel-header class="FAQ">
                      13.How do i get the document verified?
                    </v-expansion-panel-header>
                    <v-expansion-panel-content
                      class="font-weight-light grey--text"
                    >
                      The document can be verified in a few clicks by
                      drag-and-drop (or selecting) on the RelievingLetter Portal
                      home page (Verify Letter option). What is great about this
                      feature is that you need not have to be registered with
                      RelievingLetter to verify the document. This allows for
                      true third-party verification.
                    </v-expansion-panel-content>
                  </v-expansion-panel>
                </v-expansion-panels>
              </v-col>
              <v-col cols="12" md="6" lg="6" sm="12" xs="12">
                <v-expansion-panels>
                  <v-expansion-panel class="rounded-lg">
                    <v-expansion-panel-header class="FAQ">
                      14.How safe is my data?
                    </v-expansion-panel-header>
                    <v-expansion-panel-content
                      class="font-weight-light grey--text"
                    >
                      RelievingLetter is highly secure. - Secured at the user
                      access level by passwords. - At the document level, it
                      uses a hashing function for tamper-proofing. - Finally at
                      the record keeping level it uses Blockchain for storing
                      immutable verification information. The blockchain entry
                      only stores summary data to ensure authenticity. The
                      document content itself is never stored on the Blockchain
                      or in any publicly accessible form.
                    </v-expansion-panel-content>
                  </v-expansion-panel>
                </v-expansion-panels>
              </v-col>
            </v-row>
          </v-col>
          <v-col cols="12">
            <v-row>
              <v-col cols="12" md="6" lg="6" sm="12" xs="12">
                <v-expansion-panels>
                  <v-expansion-panel class="rounded-lg">
                    <v-expansion-panel-header class="FAQ">
                      15.What about the charges?
                    </v-expansion-panel-header>
                    <v-expansion-panel-content
                      class="font-weight-light grey--text"
                    >
                      Before the expiry of the free trial period, the
                      organisation has to get verified for continued usage.
                      There is a small annual fee for the verified status,
                      priced according to organisation size.
                    </v-expansion-panel-content>
                  </v-expansion-panel>
                </v-expansion-panels>
              </v-col>
              <v-col cols="12" md="6" lg="6" sm="12" xs="12">
                <v-expansion-panels>
                  <v-expansion-panel class="rounded-lg">
                    <v-expansion-panel-header class="FAQ">
                      16.How would I benefit as an issuer?
                    </v-expansion-panel-header>
                    <v-expansion-panel-content
                      class="font-weight-light grey--text"
                    >
                      RelievingLetter is the most time-efficient and
                      cost-effective process by which day to day digital
                      relieving letters and related documents can be secured.
                      You only need to issue the document once. No further
                      expensive verification requests need to be entertained, as
                      the verifiers can do this by themselves with a few clicks.
                    </v-expansion-panel-content>
                  </v-expansion-panel>
                </v-expansion-panels>
              </v-col>
            </v-row>
          </v-col>
          <v-col cols="12">
            <v-row>
              <v-col cols="12" md="6" lg="6" sm="12" xs="12">
                <v-expansion-panels>
                  <v-expansion-panel class="rounded-lg">
                    <v-expansion-panel-header class="FAQ">
                      17.How would I benefit as a Verifier?
                    </v-expansion-panel-header>
                    <v-expansion-panel-content
                      class="font-weight-light grey--text"
                    >
                      Verification is simple and free of cost. The
                      RelievingLetter portal is available to anyone. However,
                      organisations that are paid subscribers can access some
                      additional details..
                    </v-expansion-panel-content>
                  </v-expansion-panel>
                </v-expansion-panels>
              </v-col>
              <v-col cols="12" md="6" lg="6" sm="12" xs="12">
                <v-expansion-panels>
                  <v-expansion-panel class="rounded-lg">
                    <v-expansion-panel-header class="FAQ">
                      18.What upcoming enhacements can we expect from
                      RelievingLetter?
                    </v-expansion-panel-header>
                    <v-expansion-panel-content
                      class="font-weight-light grey--text"
                    >
                      Costly background verification can be eliminated
                      completely, as the authenticity of the document can be
                      established with a few clicks. This can save thousands of
                      rupees in costs, and weeks of delay
                    </v-expansion-panel-content>
                  </v-expansion-panel>
                </v-expansion-panels>
              </v-col>
            </v-row>
          </v-col>
        </v-row>
      </div>
    </section>
    <v-row class="mx-12 footer">
      <v-col cols="12" md="4" lg="4" sm="12" xs="12">
        <v-img height="50" width="160" src="@/assets/logo-dark@2x.png"></v-img>
        <h3 class="footer2">© 2023 SureCreds Pvt. Ltd.</h3>
        <h3 class="footer2">All rights reserved</h3>
      </v-col>
      <v-col cols="12" md="4" lg="4" sm="12" xs="12">
        <h3 class="footer1">Get In Touch</h3>
        <h3 class="footer2">No. 588, HMT Layout</h3>
        <h3 class="footer2">Vidyaranyapura</h3>
        <h3 class="footer2">Bangalore - 560097</h3>
        <h3 class="footer2">contactus@relievingletter.com</h3>
      </v-col>
      <v-col cols="12" md="4" lg="4" sm="12" xs="12">
        <h3 class="footer1">Learn More</h3>
        <h3 class="footer2" style="cursor: pointer" @click="scrollToTop()">
          Home
        </h3>
        <h3
          class="footer2"
          style="cursor: pointer"
          @click="$router.push('/Terms&Conditions')"
        >
          Terms of Use
        </h3>
        <h3
          class="footer2"
          style="cursor: pointer"
          @click="$router.push('/PrivacyPolicy')"
        >
          Privacy Policy
        </h3>
      </v-col>
    </v-row>
  </div>
</template>
<script>
export default {
  data: () => ({
    selectedItem: 0,
    isDarkMode: false,
    drawer: false,
    menuItems: [
      { icon: "mdi-home", title: "Home", link: "HOME" },
      { icon: "mdi-information", title: "About", link: "ABOUT" },
      {
        icon: "mdi-frequently-asked-questions",
        title: "Faqs",
        link: "FAQs",
      },

      { icon: "mdi-key-chain", title: "Sign in", link: "/Login" },
      { icon: "mdi-account-circle", title: "Sign Up", link: "/RegisterWithUs" },
      { icon: "mdi-eye", title: "Verify Letter", link: "/VerifyCredentials" },
    ],
    items: [
      {
        src: "@/assets/Image2.jpg",
      },
      {
        src: "https://cdn.vuetifyjs.com/images/carousel/sky.jpg",
      },
      {
        src: "https://cdn.vuetifyjs.com/images/carousel/bird.jpg",
      },
      {
        src: "https://cdn.vuetifyjs.com/images/carousel/planet.jpg",
      },
    ],
  }),
  computed: {
    isXsOrSm() {
      return this.$vuetify.breakpoint.xs || this.$vuetify.breakpoint.sm; // ||this.$vuetify.breakpoint.md
    },

    height() {
      switch (this.$vuetify.breakpoint.name) {
        case "xs":
          return 220;
        case "sm":
          return 400;
        case "md":
          return 500;
        case "lg":
          return 600;
        case "xl":
          return 800;
      }
    },
  },

  mounted() {
    window.scrollTo(0, 0);
    console.log("Helo");
  },
  methods: {
    toggleMode() {
      this.isDarkMode = !this.isDarkMode;
    },
    check(val) {
      console.log("Vsllll", val);
      switch (val) {
        case "ABOUT":
          this.$vuetify.goTo("#About");
          break;
        case "FAQs":
          this.$vuetify.goTo("#FAQs");
          break;
        case "HOME":
          this.$vuetify.goTo("#Home");
          break;
        case "/Login":
          this.$router.push(val);
          break;
        case "/RegisterWithUs":
          this.$router.push(val);
          break;
        case "/VerifyCredentials":
          this.$router.push(val);
          break;
      }
    },

    scrollToTop() {
      window.scrollTo(0, 0);
    },
  },
};
</script>
<style>
#verifyButton {
  /* color:black !important; */
  height: 45px !important;
  border-radius: 10px !important;
}
@media (min-width: 768px) {
  /* CSS rules for laptop and higher devices */
  .fakeletter {
    margin-top: 90px !important;
  }
}
.Log {
  /* color:green !important; */
  height: 45px !important;
  border-radius: 10px !important;
}

/* .heading1 {
  font-family: Inter, ui-sans-serif, system-ui, sans-serif, Apple Color Emoji,
    Segoe UI Emoji, Segoe UI Symbol, Noto Color Emoji; 
  font-family:Klavika;
  font-size: 50px;
  color: #23bf1f;
} */

/* .heading2 {
   font-family: Inter, ui-sans-serif, system-ui, sans-serif, Apple Color Emoji,
    Segoe UI Emoji, Segoe UI Symbol, Noto Color Emoji; 
  font-size: 20px !important;
   font-family: Klavika; 
  font-weight: 400;
  color: #383838;
} */

/* .heading3 {
 font-family: Inter, ui-sans-serif, system-ui, sans-serif, Apple Color Emoji,
    Segoe UI Emoji, Segoe UI Symbol, Noto Color Emoji; 
  font-size: 35px !important;
  font-weight: 500;
  color: rgb(80, 79, 79);
} */
/* .heading1 {
  font-size: 50px;
  color: rgb(80, 79, 79);
}
.heading2 {
  font-size: 20px !important;
  font-weight: 400;
  color: rgb(166, 164, 164);
}
.heading3 {
  font-size: 35px !important;
  font-weight: 500;
  color: rgb(80, 79, 79);
} */
.heading1 {
  font-size: 50px !important;
  color: rgb(80, 79, 79) !important;
}
.heading2 {
  font-size: 20px !important;
  font-weight: 400;
  color: rgb(166, 164, 164);
}
.heading3 {
  font-size: 35px !important;
  font-weight: 500;
  color: rgb(80, 79, 79);
}
.about {
  height: 300px;
}
.FAQ {
  /* font-family: Inter, ui-sans-serif, system-ui, sans-serif, Apple Color Emoji,
    Segoe UI Emoji, Segoe UI Symbol, Noto Color Emoji; */
  /* font-family: Klavika; */
  font-size: 35px !important;
  font-weight: 500;
  color: rgb(80, 79, 79);
  font-size: 18px !important;
}
.FAQ:hover {
  color: green;
}
.Rlfaq {
  font-size: 35px !important;
  font-weight: 500;
  color: rgb(80, 79, 79);
  font-size: 18px !important;
}

.v-expansion-panel-header {
  height: 60px; /* Adjust the height as needed */
}
.footer {
  margin-top: 100px !important;
  margin-bottom: 20px !important;
}
.footer2 {
  font-size: 16px;
  color: rgb(122, 122, 122);
  font-weight: 400;
}
.footer1 {
  font-size: 20px;
  color: rgb(68, 68, 68);
  font-weight: 700;
}
.dark-mode {
  background-color: #887c7c;
  color: white;
}

.My_Button:hover {
  color: greenyellow !important;
}

@media (max-width: 550px) {
  .abc {
    text-align: center !important;
  }
}
/* .abc:hover{
  color:greenyellow !important
} */
.buttonhover:hover {
  color: greenyellow !important;
}
.iconcss {
  text-align: right !important;
}
</style>
