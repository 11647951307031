import Vue from "vue";
import Vuex from "vuex";
import createPersistedState from "vuex-persistedstate";
Vue.use(Vuex);
export default new Vuex.Store({
  plugins: [createPersistedState()],
  state: {
    EmailID: "",
    Password: "",
    user_type: "",
    GetCurrentUser: [],
    GetCurrentOrg: [],
    GetTeamList: [],
    account_logo_url: "",
    ListVeirifiedDocuments: [],
    RL_listReasonOfLeaving: [],
  },
  getters: {
    get_user_email: (state) => {
      return state.EmailID;
    },
    get_user_password: (state) => {
      return state.Password;
    },
    get_current_user_details: (state) => {
      return state.GetCurrentUser;
    },
    get_user_type: (state) => {
      return state.user_type;
    },
    get_ListVeirifiedDocuments: (state) => {
      return state.ListVeirifiedDocuments;
    },
    get_Rl_ReasonsForLeaving_details: (state) => {
      return state.RL_listReasonOfLeaving;
    },
  },
  mutations: {
    SET_USEREMAIL(state, username) {
      state.EmailID = username;
    },
    SET_USERPASSWORD(state, Password) {
      state.Password = Password;
    },
    SET_CURRENTUSER_DETAILS(state, GetCurrentUserDetails) {
      state.GetCurrentUser = GetCurrentUserDetails;
    },
    SET_USER_TYPE(state, user_type) {
      state.user_type = user_type;
    },

    SET_ACCOUNT_LOGO_URL(state, account_logo) {
      state.account_logo_url = account_logo;
    },
    SET_ListVeirifiedDocuments(state, ListVeirifiedDocumentsDetails) {
      state.ListVeirifiedDocuments = ListVeirifiedDocumentsDetails;
    },
    SET_RL_REASON_OF_LEAVING(state, Rl_Reasons_of_LeavingDetails) {
      state.RL_listReasonOfLeaving = Rl_Reasons_of_LeavingDetails;
    },
  },
  actions: {
    resetState({ commit }) {
      commit("SET_USEREMAIL", "");
    },
    setUserDetailsObjEmpty({ commit }) {
      commit("SET_CURRENTUSER_DETAILS", null);
    },
    setUserEmailEmpty({ commit }) {
      commit("SET_USEREMAIL", "");
    },
  },
  modules: {},
});
