import Vue from "vue";
import VueRouter from "vue-router";
Vue.use(VueRouter);
import HomePage from "../views/ChildHomePages/LoginPages/Home.vue";

const routes = [
  {
    path: "/Home",
    name: "Home",
    component: HomePage,
  },
  {
    path: "/Terms&Conditions",
    name: "Terms&Conditions",
    component: () =>
      import(
        /* webpackChunkName: "LoginPage" */ "../views/ChildHomePages/LoginPages/Terms&Conditions.vue"
      ),
  },
  {
    path: "/PrivacyPolicy",
    name: "PrivacyPolicy",
    component: () =>
      import(
        /* webpackChunkName: "LoginPage" */ "../views/ChildHomePages/LoginPages/PrivacyPolicy.vue"
      ),
  },
  {
    path: "/Login",
    name: "Login",
    component: () =>
      import(
        /* webpackChunkName: "LoginPage" */ "../views/ChildHomePages/LoginPages/Login.vue"
      ),
  },
  {
    path: "/RegisterWithUs",
    name: "RegisterWithUs",
    component: () =>
      import(
        /* webpackChunkName: "PaymentSuccessful" */ "../views/MainHomePages/RegisterWithUs.vue"
      ),
  },
  {
    path: "/VerifyCredentials",
    name: "VerifyCredentials",
    component: () =>
      import(
        /* webpackChunkName: "LoginPage" */ "../views/ChildHomePages/LoginPages/VerifyCredentials.vue"
      ),
  },
  {
    path: "/organizationDetials",
    name: "organizationDetails",
    component: () =>
      import(
        /* webpackChunkName: "PaymentSuccessful" */ "../views/ChildHomePages/LoginPages/organizationDetials.vue"
      ),
  },
  {
    path: "/otpPage",
    name: "otpPage",
    component: () =>
      import(
        /* webpackChunkName: "PaymentSuccessful" */ "../views/ChildHomePages/LoginPages/otpPage.vue"
      ),
  },
  {
    path: "/LandingPage",
    name: "LandingPage",
    component: () =>
      import(
        /* webpackChunkName: "LandingPage" */ "../views/MainHomePages/LandingPage.vue"
      ),
    children: [
      {
        path: "/Users",
        name: "Users",
        component: () =>
          import(
            /* webpackChunkName: "LandingPage" */ "../views/ChildHomePages/LandingPages/Users.vue"
          ),
      },
      {
        path: "/OrganizationSettings",
        name: "OrganizationSettings",
        component: () =>
          import(
            /* webpackChunkName: "LandingPage" */ "../views/ChildHomePages/LandingPages/OrganizationSettings.vue"
          ),
      },
      {
        path: "/Payload",
        name: "Payload",
        component: () =>
          import(
            /* webpackChunkName: "LandingPage" */ "../views/ChildHomePages/LandingPages/Payload.vue"
          ),
      },
      {
        path: "/BusinessInfo",
        name: "BusinessInfo",
        component: () =>
          import(
            /* webpackChunkName: "LandingPage" */ "../views/ChildHomePages/LandingPages/NewBussinessInfo.vue"
          ),
      },
      {
        path: "/Dashboard",
        name: "Dashboard",
        component: () =>
          import(
            /* webpackChunkName: "LandingPage" */ "../views/ChildHomePages/LandingPages/DashboardNew.vue"
          ),
      },
      {
        path: "/VerificationRequests",
        name: "VerificationRequests",
        component: () =>
          import(
            /* webpackChunkName: "LoginPage" */ "../views/ChildHomePages/LandingPages/VerificationRequests.vue"
          ),
      },
      {
        path: "/Media",
        name: "Media",
        component: () =>
          import(
            /* webpackChunkName: "LandingPage" */ "../views/ChildHomePages/LandingPages/Media.vue"
          ),
      },
      {
        path: "/Templates",
        name: "Templates",
        component: () =>
          import(
            /* webpackChunkName: "LandingPage" */ "../views/ChildHomePages/LandingPages/Templates.vue"
          ),
      },
      {
        path: "/MyProfile",
        name: "MyProfile",
        component: () =>
          import(
            /* webpackChunkName: "LandingPage" */ "../views/ChildHomePages/LandingPages/MyProfile.vue"
          ),
      },
      {
        path: "/UpdateOrganization",
        name: "UpdateOrganization",
        component: () =>
          import(
            /* webpackChunkName: "LandingPage" */ "../views/ChildHomePages/LandingPages/UpdateOrganization.vue"
          ),
      },
    ],
  },
  {
    path: "/PaymentSuccessful",
    name: "PaymentSuccessful",
    component: () =>
      import(
        /* webpackChunkName: "PaymentSuccessful" */ "../views/MainHomePages/PaymentSuccessful.vue"
      ),
  },
  {
    path: "/ErrorRoute",
    name: "ErrorRoute",
    component: () =>
      import(
        /* webpackChunkName: "ErrorRoute" */ "../views/MainHomePages/ErrorRoute.vue"
      ),
  },
];
const router = new VueRouter({
  mode: "history",
  base: `${process.env.BASE_URL}`,
  routes,
});
export default router;
